@import 'src/styles/services';

.root {
  position: relative;
}

.wrapper {
  width: 300px;
  background-color: $color-white;
  color: $color-black;
  padding: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  row-gap: 16px;

  .bonusButton {
    color: $color-yellow;
  }
}

.bonus {
  color: $color-red;
}

.text {
  font-size: 13px;
  line-height: 16px;

  &.right {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;

    .register {
      white-space: nowrap;
    }

    .bonus {
      white-space: nowrap;
    }
  }
}

.close {
  top: -20px;
  right: -20px;
}

.popup {
  .content {
    .bonusBgColor {
      background-color: $color-white;
    }
  }
}
