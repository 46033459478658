@import 'src/styles/services';

.root {
  background: $color-red;
  border-radius: $border-md;
  padding: 12px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bonus {
  font-style: italic;
  font-weight: 900;
  font-size: 32px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: $color-yellow-light;
  text-shadow: 0 4px 10px rgba(252, 245, 1, 0.51);
  z-index: 1;

  @include media-width-down($screen-md) {
    font-size: 26px;
  }
}

.deposit {
  font-style: italic;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: $color-white;
  text-shadow: 0 4px 10px rgba(255, 255, 255, 0.5);
  margin-top: 8px;
  margin-bottom: 16px;
  z-index: 1;
}

.backImages {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 0;
}

.imgContainer {
  position: relative;
  width: 50%;
  display: flex;
}
