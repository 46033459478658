@import 'src/styles/services';

.root {
  cursor: pointer;
  display: flex;
  column-gap: 10px;
  user-select: none;
}

.dropdownHeader {
  display: flex;
  align-items: center;
}

.dropdownContainer {
  width: auto;
}

.mobileSelect {
  opacity: 0;
  position: absolute;
  width: 100%;
}

.mobileSelectWrapper {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.flag {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 100%;
  overflow: hidden;
}

.text {
  font-weight: bold;
  text-transform: uppercase;
  transition: trs(color);
  color: $color-grey-light;
  overflow: hidden;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;

  .selected & {
    color: $color-grey;
  }

  .root:not(.active) & {
    color: $color-white;
  }

  .root.active & {
    color: $color-red;
  }

  .locale:hover & {
    color: $color-white;
  }
}

.arrow {
  color: $color-grey;
}

.locales {
  gap: 8px;
}

.listLocales {
  display: flex;
  flex-direction: column;
}

.gridLocales {
  display: flex;
  flex-direction: column;
}

.listRow {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 12px 8px 12px;

  &:not(:last-of-type) {
    border-bottom: 1px solid $color-black;
  }
}

.gridDropdownContainer {
  padding: 0;
}

.headerContainer {
  font-size: 14px;
  border-bottom: 1px solid $color-black;
  color: $color-red;
  padding-bottom: 5px;
  margin-bottom: 5px;
  position: sticky;
  top: 0;
  background-color: $color-grey-dark;
  z-index: 2;

  .arrow {
    color: $color-white;
    rotate: 180deg;
    flex: 1;
  }
}

.gridHeaderContainer {
  padding: 12px;
  margin-bottom: 8px;
}

.locale {
  cursor: pointer;
  display: flex;
  column-gap: 10px;

  .gridLocales & {
    width: 50px;
    flex: 0 0 50px;
  }
}
