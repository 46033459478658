@import 'src/styles/services';

.root {
  display: flex;
  gap: 8px;
  align-items: center;
}

.progress {
  margin-left: 8px;
  margin-block: 8px;
  height: 90px;
  width: 90px;
}

.text {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  text-transform: uppercase;
}

.close {
  width: 10px;
  min-width: 10px;
  height: 10px;
  margin: 10px;
  color: $color-grey;
  margin-left: auto;
  cursor: pointer;
}

.headerBalance {
  display: inline-flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.05em;
}

.header {
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: $color-yellow;
}

.expirationDate {
  font-weight: 700;
  font-size: 8px;
  letter-spacing: 0.4px;
  color: $color-grey-light;
  display: inline-flex;
  gap: 4px;
}

.burnOut {
  font-size: 8px;
  color: $color-white;
}

.progressValue {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
  top: -3px;

  strong {
    line-height: 1;
    color: $color-red;
  }
}
