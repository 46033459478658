@import 'src/styles/services';

.root {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -48px;
  z-index: $mobile-menu-center-button-z-index;
  cursor: pointer;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 56px;
  border: 1px solid $color-white;
  background: $color-white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.border {
  border: 1px solid $color-red;
}

.leftCornerIconContainer {
  position: absolute;
  left: -1px;
  bottom: 1px;
}

.leftCornerIcon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 112px;
  width: 16px;
  height: 16px;
  background: $color-white;
  color: $color-red;
}

.arc {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 18px;
  height: 18px;
  border-radius: 112px;
  border: 1px solid;
}

.arcStart {
  border-color: transparent transparent transparent $color-red;
  transform: rotate(-9deg);
}

.arcEnd {
  border-color: $color-red transparent transparent transparent;
  transform: rotate(200deg);
}

.counter {
  position: absolute;
  top: -3px;
  right: -3px;
  border-radius: 50%;
  height: 16px;
  min-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}

.redCounterBackground {
  background-color: $color-red;
}

.yellowCounterBackground {
  background-color: $color-yellow;
}
