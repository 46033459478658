@import 'src/styles/services';

.root {
  overflow: hidden;
}

.text {
  width: 100%;
  text-align: start;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;

  &.uppercase {
    text-transform: uppercase;
  }
}

.icon {
  width: 100%;
}
