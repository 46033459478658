@import 'src/styles/services';

.root {
  display: flex;
  flex-direction: column;
  margin: -8px;

  .item:not(:first-of-type) {
    border-top: 1px solid $color-black;
  }
}

.accordeonText {
  display: flex;
  align-items: center;
}

.accordeonButton {
  border: none;

  @include hover {
    .accordeonText {
      color: $color-red-light;
    }
  }
}

.giftIcon {
  vertical-align: middle;
  margin-right: 8px;
}
