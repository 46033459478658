.root {
  align-self: center;
  flex-shrink: 0;
}

.icon {
  height: 30px;
  width: auto;
  display: block;
}
