@import 'src/styles/services';

$inner-padding: 10px;

.line {
  width: 100%;
  display: flex;
  gap: 8px;
}

.progressContainer {
  height: 40px;
  max-height: 100%;
  background: $color-black;
  border-radius: 100px;
  width: 100%;
  position: relative;
  padding: $inner-padding;

  .progress {
    position: relative;
    z-index: 1;
    height: 100%;
    background: linear-gradient(0deg, $color-red, $color-red),
      linear-gradient(90deg, $color-red 0%, #ff6332 33.33%, #dbca32 66.67%, #a5ff32 100%);
    border-radius: 9999px;
    box-shadow: 0 0 4px $color-red;
  }

  .shadow {
    position: absolute;
    top: 0;
    height: calc(100% - $inner-padding * 2);
    margin: $inner-padding;
    background: $color-black-light;
    border-radius: inherit;
    width: 100%;
  }
}

.circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.circle .circleSvg {
  transform: rotate(-90deg);
}

.circleSvg .track {
  stroke: $color-black;
}

.circleSvg .progress {
  stroke: $color-red;
}

.valueContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.value {
  position: absolute;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
}

.line .value {
  color: $color-black;
}

.circle .value {
  color: $color-white;
}
