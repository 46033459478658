@import 'src/styles/services';

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 10px;

  // TODO: calculate padding-bottom via MobileMenu height
  @include media-width-down($screen-850) {
    padding-bottom: 70px;
  }
}

.content {
  display: flex;
  gap: 8px;
  justify-content: space-between;

  @include media-width-down($screen-xs) {
    flex-direction: column;
  }
}

.logo {
  align-self: center;
}

.copyright {
  display: flex;
  place-items: flex-start;
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.5;
  color: $color-grey;

  @include media-width-down($screen-xs) {
    font-size: 12px;
  }
}

.additional {
  display: flex;
  gap: 18px;
  place-content: flex-start;
  flex-direction: column;

  @include media-width-down($screen-xs) {
    place-items: center;
  }
}

.additionalRow {
  display: flex;
  gap: 8px;
  place-items: center;
}

.download {
  width: 100%;
  gap: 8px;
}

.downloadButton {
  flex: 1;
}

.payments {
  margin-inline-end: auto;
}

.navigation {
  grid-area: navigation;
  text-align: center;

  & > * {
    font-size: 18px;
    line-height: 24px;
  }

  @include media-width-down($screen-sm) {
    padding: 8px 0;
  }
}
