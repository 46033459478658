@import 'src/styles/services';

.root {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  align-items: stretch;
  z-index: $sidebar-z-index;
  justify-content: flex-end;

  &.render {
    display: flex;
  }

  .scrollbarContainer {
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.2s ease;
    opacity: 0;
    z-index: calc($sidebar-z-index - 1);
    display: flex;
    justify-content: flex-end;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 404px;
    height: 100%;
    transition: transform 0.2s ease;
    transform: translateX(100%);
    z-index: calc($sidebar-z-index + 1);

    @include media-width-down($screen-xs) {
      width: 344px;
    }

    @include media-width-down($screen-360) {
      width: 100%;
    }
  }

  &.visible {
    .overlay {
      opacity: 1;
    }

    .container {
      transform: translateX(0);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $color-black;
}

.topContent {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px;

  .logoImage {
    position: relative;
    min-height: 40px;
    width: 234px;
  }

  .closeButton {
    position: absolute;
    right: 10px;
    top: 18px;
  }
}

.scrollbar {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  padding: 10px;
  padding-top: 0;
  padding-bottom: 16px;
}

.itemLink {
  padding: 8px 14px 8px 8px;
  border-radius: 8px;
  justify-content: flex-start;
  font-weight: 900;
  font-size: 15px;
  line-height: 100%;
  background: $color-black-light;
  display: flex;
  align-items: center;
}

.itemHeader {
  display: flex;
  align-items: center;
}

.subItemLinks {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  .subItemLink {
    font-weight: 900;
  }
}

.icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.sidebarAccordeonItemButton {
  border: none;
}

.divider {
  width: 100%;
  height: 1px;
  background: radial-gradient(61.85% 9120000.42% at 49.61% 0%, #292c33 0%, rgba(41, 44, 51, 0) 100%);
}

.blockHeader {
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffa902;
  text-transform: uppercase;
  margin-bottom: -8px;
}

.socialListContainer {
  flex: 1;
  display: flex;
  align-items: flex-end;

  .list svg {
    color: $color-grey;
  }
}

.downloadButton {
  flex-grow: 1;
  flex-basis: 50%;
}

.localeButton {
  display: flex;
  place-content: flex-end;
}

.staticGroup {
  border: 1px solid #52555a;
  border-radius: $border-lg;
  gap: 0;

  .subItemLink {
    padding: 8px;
    width: 100%;
    justify-content: flex-start;

    &:hover {
      color: $color-red;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #52555a;
    }
  }
}
