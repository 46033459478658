@import 'src/styles/services';

.root {
  display: flex;
  gap: 10px;
  filter: grayscale(100%);

  @include media-width-down($screen-xs) {
    place-content: center;
  }
}
