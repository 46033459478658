@import 'src/styles/services';

.item {
  overflow: initial;
  text-transform: uppercase;
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 900;
  scroll-snap-align: start;

  @include hover {
    &:not(.active) {
      color: $color-red;
    }
  }
}

.buyBonusIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.aviator {
  height: 40px;
  padding: 0 8px;
  flex-shrink: 0;

  svg {
    margin-bottom: 5px;
  }
}

@include media-width-down($screen-850) {
  .item {
    padding: 6.5px 9px;
    font-size: 12px;
  }
}
