@import 'src/styles/services';

.navItem {
  &.navItem {
    font-weight: 900;
    justify-content: flex-start;
    padding-inline: 8px;
    font-size: 16px;
    line-height: 24px;

    + .navItem {
      margin-top: 8px;
    }

    &.active {
      color: $color-red;
    }

    @include hover {
      color: $color-red;
    }
  }
}
