@import 'src/styles/services';

.root {
  .icon {
    transition: trs(color);
    color: $color-grey;
    margin-top: 5px;
  }
}

.active {
  .icon {
    color: $color-white;
  }
}

@include media-width-down($screen-850) {
  .root {
    .itemText {
      text-transform: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      color: $color-white;
      margin-bottom: 6px;
      font-size: 7px;
      font-weight: 800;
      line-height: 13.5px;
      letter-spacing: 0.05em;
      text-align: center;
    }
  }
}
