@import 'src/styles/services';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  place-content: center;

  @include media-width-down($screen-xs) {
    flex-direction: column;
  }
}

.link {
  white-space: pre-wrap;
}
