@import 'src/styles/services';

$offset: 2.5px;

.root {
  position: relative;
}

.count {
  min-width: 16px;
  min-height: 16px;
  position: absolute;
  background-color: $color-red;
  padding: 2px 5px;
  line-height: 0;
  color: $color-white;
  display: flex;
  place-items: center;
  justify-content: center;
  aspect-ratio: 1;
  border-radius: 100%;
  user-select: none;
  top: 0;
  right: 0;
  transform: translate(calc(50% - $offset), calc(-50% + $offset));
  z-index: 2;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
    height: 0;
  }
}
