@import 'src/styles/services';

.root {
  display: flex;
  place-items: center;
}

.icon {
  gap: 8px;
  padding: 10px;
  border-radius: $border-lg;
  background-color: $color-black-light;
}

.default {
  gap: 16px;

  .button {
    border-radius: $border-md;
    padding-inline: 8px;
    padding-block: 5px;
    background-color: $color-black-light;
    display: flex;
    gap: 8px;
    place-items: center;
    place-content: center;
    text-align: left;
    text-transform: none;
  }
}

.label {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: $color-grey-light;
}

.platform {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
}

.platformIOS {
  color: $color-white;
}

.platformAndroid {
  color: $color-android;
}
