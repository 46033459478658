@import 'src/styles/services';

.link {
  white-space: normal;
  height: auto;
  padding: 0;
  border-radius: 0;
  display: flex;
  flex-grow: 1;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  background: none;
  margin: 8px;
}

.root {
  display: flex;
  gap: 8px;
  align-items: center;

  .link {
    @include hover {
      text-shadow: none;
    }
  }
}

.image {
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: $color-grey;
  border: 1px solid $color-grey;
  object-fit: cover;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
}

.close {
  width: 10px;
  min-width: 10px;
  height: 10px;
  margin: 10px;
  margin-left: auto;
  color: $color-grey;
  cursor: pointer;
}

.headerTitle {
  display: inline-flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  margin-top: 8px;
}

.header {
  font-weight: 900;
  font-size: 18px;
  color: $color-yellow;
}

.gameTitle {
  text-transform: none;
}

.expirationDate {
  font-weight: 700;
  font-size: 8px;
  letter-spacing: 0.4px;
  color: $color-grey-light;
  display: inline-flex;
  gap: 4px;
}

.burnOut {
  font-size: 8px;
  color: $color-white;
}
