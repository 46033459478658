@import 'src/styles/services';

.root {
  display: flex;
  width: calc(100% + 50px);
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  height: 40px;
  overflow: hidden;
  background: url(/assets/sidebar/images/cashbackPattern.webp) repeat center center / contain;
  margin-top: 8px;
}

.amount {
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: $color-white;
  unicode-bidi: bidi-override;
}

.recieveBtn {
  color: $color-white !important; // stylelint-disable-line declaration-no-important
  border-radius: 5px;
  padding: 0 8px;
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
  letter-spacing: 0.7px;
}

.untilNext {
  font-size: 13px;
  color: $color-white;
  display: inline-flex;
  gap: 4px;
}
