@import 'src/styles/services';

.root {
  display: flex;
  place-content: flex-end;
  align-self: center;
}

.icon {
  height: 40px;
  width: auto;
  display: block;

  @include media-width-down($screen-sm) {
    height: 22px;
  }
}
