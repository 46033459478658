@import 'src/styles/services';

.root {
  display: none;
  position: fixed;
  inset: auto 0 0 0;
  background-color: $color-black;
  padding: 2px 8px;
  z-index: $mobile-menu-z-index;
  border-radius: 10px 10px 0 0;
  align-items: flex-start;
  justify-content: space-evenly;
  border: 1px solid $color-black-light;
  border-bottom: 0;
  height: $mobile-menu-height-xs;

  :global([data-tippy-root]) {
    width: 100vw;
    max-width: unset;
    overflow: hidden;
    border-bottom: 0;
    /* stylelint-disable declaration-no-important */
    bottom: -5px !important;
    right: -2px !important;
    /* stylelint-enable declaration-no-important */
  }

  :global(.tippy-box) {
    border-radius: $border-lg $border-lg 0 0;
    padding-bottom: 5px;
  }

  :global(.tippy-content) {
    padding: 0;
  }

  .item {
    flex: 1;
    z-index: 2;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    white-space: pre-wrap;
    text-align: center;
    max-width: 68px;
  }

  .activeItem {
    height: 53px;
    clip-path: polygon(0 0, 100% 8%, 100% 92%, 0% 100%);
    background: red;
    flex: 1;
    z-index: 2;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    white-space: pre-wrap;
    text-align: center;
  }

  .arrow {
    width: 40px;
    height: 40px;
    display: flex;
  }

  .arrowIcon {
    transition: trs(color, transform);
    transform: rotate(0deg);

    &.expanded {
      transform: rotate(180deg);
    }
  }
}

.tippy {
  background-color: $color-black;
}

.children {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: $color-black;
  border-top: 1px solid $color-black-light;
  border-radius: 10px 10px 0 0;

  .child {
    padding: 4px 0;
    line-height: 1.5;
  }

  .child.active {
    padding: 10px 0;
    margin: -8px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include media-width-down($screen-850) {
  .root {
    display: flex;
  }
}
