@import 'src/styles/services';

.root {
  padding: 7px 65px 7px 10px;
  font-size: 15px;
  font-weight: 900;
  line-height: 24px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: $color-white;
  position: relative;
  overflow: visible;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background: url('/assets/layout/images/headerButtonPattern.webp') repeat-x center / 33%;
    opacity: 0.05;
    z-index: -1;
    transition: trs(opacity);
  }

  &:hover {
    box-shadow: none;

    &::before {
      opacity: 0.1;
    }
  }

  &.yellowColor {
    background-image: linear-gradient(180deg, #eb9500 0%, #f5c720 100%);
    box-shadow: 0 4px 16px 0 rgba(255, 168, 0, 0.5);

    &:hover {
      background-image: linear-gradient(180deg, #ffa914 0%, #ffdb34 100%);
      text-shadow: 0 2px 0 #f2a620;
    }
  }

  &.purpleColor {
    background-image: linear-gradient(180deg, #62007b 0%, #b83dab 100%);
    box-shadow: 0 4px 16px 0 rgba(184, 61, 171, 0.5);

    &:hover {
      background-image: linear-gradient(180deg, #76148f 0%, #cc51bf 100%);
      text-shadow: 0 2px 0 #7b1c90;
    }
  }
}

.image {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  overflow: hidden;
  border-top-right-radius: $border-sm;
  border-bottom-right-radius: $border-sm;
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -300%;
  background-size: cover;
  transition: trs(background-position-y);

  .root:hover & {
    background-position-y: -50%;
  }
}
