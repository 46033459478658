@import 'src/styles/services';

.root.root {
  font-size: 14px;
  line-height: 24px;
  padding: 0 8px 0 43px;
  overflow: visible;
  font-weight: 900;
}

.text {
  position: relative;
  z-index: 1;
}

.bg {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: calc(100% - 32px);
  height: auto;
}

@include media-width-down($screen-xl) {
  .root.root {
    padding: 0;

    .text,
    .bg {
      display: none;
    }
  }
}
