@import 'src/styles/services';

.signButton {
  padding: 8px 16px;
  border-radius: $border-sm;
  text-transform: uppercase;

  @include media-width-down($screen-xs) {
    &.gameMode {
      height: 40px;
      min-width: 110px;
      display: flex;
    }

    @media (orientation: landscape) {
      &.gameMode {
        transform: rotate(180deg);
        writing-mode: vertical-rl;
        text-orientation: mixed;
        min-width: unset;
        width: 40px;
        height: auto;
        font-size: 18px;
        padding: 16px;

        @media (max-height: 330px) {
          max-height: 110px;

          .text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.text {
  font-size: 18px;
  font-weight: 900;
}
