@import 'src/styles/services';

.root {
  z-index: 2;
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.wheelBody {
  transform-origin: center;
  animation: rotate 6s ease-in-out 5s infinite;
}

@include media-width-down($screen-xl) {
  .root {
    position: static;
    transform: none;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  83% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
