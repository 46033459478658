@import 'src/styles/services';

.root {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(2, 1fr);
  gap: 0 8px;
  align-items: center;

  .title {
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $color-red;
  }

  .text {
    color: $color-white;
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
  }

  .ranks {
    grid-row: 1/3;
    grid-column: 2/3;
    display: flex;
    gap: 0 8px;
    align-items: center;

    .img {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
    }
  }
}
