@import 'src/styles/services';

.root {
  padding: 16px 10px 10px;
  background-color: $color-black;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: $header-z-index;
  transition: trs(transform);

  &.hidden {
    transform: translateY(-100%);
  }
}

@include media-width-down($screen-850) {
  .root {
    padding: 16px 10px;
  }
}
