@import 'src/styles/services';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: $color-white;
  background-color: $color-black;
  overflow: hidden;

  &.webView {
    padding-top: 10px;
  }

  &.sticky {
    overflow: visible;
  }
}

.background {
  background: $color-black url('/assets/promo/noDepositBonus/images/background.webp') no-repeat center center / cover;
}

.content {
  flex: 1;
  padding-top: var(--header-height, $header-height);

  .cover & {
    display: flex;
    flex-direction: column;
  }

  .webView & {
    padding-top: 0;
  }
}

@include media-width-down($screen-360) {
  .root {
    overflow-x: auto;
    min-width: 320px;
  }
}

@include media-width-down($screen-xs) {
  @media (orientation: landscape) {
    .banner {
      padding-left: 80px;
    }
  }
}
