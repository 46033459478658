@import 'src/styles/services';

.root {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  gap: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.userNameContainer {
  display: inline-flex;
  align-items: center;

  .userName {
    display: block;
    color: $color-yellow;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include media-width-down($screen-xs) {
      max-width: 186px;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
  color: $color-grey;
}

.userId {
  color: $color-grey;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}
