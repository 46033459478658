@import 'src/styles/services';

.root {
  isolation: isolate;
  padding: 8px 0;
  text-align: right;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 22px;
  line-height: 24px;
  line-height: 1;
  font-weight: 900;
}

.wrapper {
  position: absolute;
  inset: 0;
  right: 34px;
  z-index: 0;
  background-color: $color-yellow;
  clip-path: polygon(0 0, 29% 0, 0 42%, 0% 62%, 32% 0, 100% 0, 94% 99%, 0 100%);
}

.balance {
  z-index: 1;
  padding-inline: 10px;
}

.add {
  border-radius: 0;
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 6% 100%);
  padding: 8px 8px 8px 10px;
  display: flex;
  align-items: center;
}

@include media-width-down($screen-850) {
  .balance {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .root {
    font-size: 15px;
  }
}
