@import 'src/styles/services';

.root {
  --rows-count: 2;

  display: grid;
  grid-template-rows: repeat(var(--rows-count), auto);
  grid-auto-flow: column;
  overflow-x: auto;
  place-items: center;
  grid-gap: 8px;
  padding: 20px 0;
}

.provider {
  --min-width: 90px;

  position: relative;
  padding: 0;
  width: clamp(var(--min-width), 7.4vw, 218px);
  height: clamp(62px, 3vw, 74px);

  &.button {
    cursor: default;
  }

  @include hover {
    filter: grayscale(0) drop-shadow(0 0 8px $color-red);
  }
}

.loader {
  position: relative;
  width: 100%;
  min-height: 300px;
}

@include media-width-down($screen-sm) {
  .root {
    --rows-count: 3;
  }

  .provider {
    --min-width: 74px;
  }
}
