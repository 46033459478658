@import 'src/styles/services';

$desktop-logo-aspect-ratio: 408 / 70;

.root {
  display: grid;
  grid-template-areas:
    'left left right'
    'logo navigation navigation';
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(2, 40px);
  gap: 16px;
  position: relative;
  place-items: center end;

  .bonusButton {
    color: $color-black;
  }
}

.logo {
  grid-area: logo;
  width: 177px;
  aspect-ratio: $desktop-logo-aspect-ratio;
}

.navigation {
  grid-area: navigation;
  display: flex;
  column-gap: 8px;
  place-items: center;
  place-content: flex-start;
  width: 100%;
  position: relative;
  scroll-snap-type: x mandatory;

  .navSlide {
    width: auto;
  }

  @include media-width-down($screen-850) {
    .root.pageWithoutNav & {
      display: none;
    }
  }
}

.search {
  grid-area: search;
}

.left {
  grid-area: left;
  display: flex;
  column-gap: 16px;
  place-items: center;
  width: 100%;
}

.right {
  grid-area: right;
  display: flex;
  column-gap: 16px;
  justify-content: flex-end;
  align-items: stretch;
}

.instantSignUp {
  display: flex;
  place-content: center;
  place-items: center;
  grid-area: instant-sign-up;
}

.logoImage {
  width: 100%;
  height: 100%;
}

.shadowLinks {
  display: flex;
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
}

.additionalButton {
  display: flex;
  align-items: center;
  float: right;
  margin-right: 16px;
  height: 100%;

  & svg {
    color: $color-grey-light;
  }
}

.additionalLink {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}

@include media-width-down($screen-md) {
  .navigation {
    overflow-x: auto;
    margin: 0 -8px;
    padding: 0 8px;
    width: calc(100% + 16px);

    @include remove-scrollbar;
  }
}

@include media-width-down($screen-850) {
  .balance {
    grid-area: balance;
    justify-self: center;
  }

  .root {
    grid-template-rows: 40px 24px;

    &.pageWithoutNav {
      grid-template-columns: 40px 1fr;
      grid-template-areas: 'logo right';
      grid-template-rows: 40px;
      row-gap: 0;

      .right {
        display: flex;
      }
    }

    &.auth {
      grid-template-columns: 40px 1fr auto;
      grid-template-areas:
        'logo balance burger'
        'navigation navigation navigation';

      .right {
        display: contents;
      }
    }

    &.auth.game {
      column-gap: 8px;
      grid-template-columns: 40px 1fr auto;
      grid-template-areas: 'logo balance burger';
      grid-template-rows: 40px;

      .navigation {
        display: none;
      }

      .right {
        display: contents;
      }

      .balance {
        place-self: flex-end;
        padding: 0;
      }
    }

    &:not(.auth) {
      grid-template-columns: 40px 1fr;
      grid-template-areas:
        'logo right'
        'navigation navigation';
    }
  }

  .logo {
    width: 42.58px;
    height: 40px;
  }

  .left {
    display: none;
  }
}
