@import 'src/styles/services';

.root {
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; // stylelint-disable-line declaration-no-important
}

.icon {
  .root:hover & {
    display: none;
  }
}

.iconHover {
  display: none;

  .root:hover & {
    display: block;
  }
}
