@import 'src/styles/services';

.root {
  display: flex;
  gap: 9px;
  width: 100%;
}

.shield {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;

  .shieldIcon {
    color: $color-red;
    width: 35px;
    height: 40px;

    .icon {
      width: 100%;
      height: 100%;
    }
  }

  .value {
    position: absolute;
    font-weight: 900;
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 0.05em;
  }
}

.progressRank {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .progressWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .levelTitle {
    color: $color-white;
    font-size: 11px;
    line-height: 8px;
    font-weight: 400;
  }

  .progressContainer {
    padding: 0;
    background: $color-black-light;
  }

  .progressBar {
    height: 4px;
  }

  .progress {
    height: 4px;
  }

  .rankExperience {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .experience {
      font-size: 11px;
      line-height: 13px;

      .current {
        color: $color-red;
      }
    }
  }
}
