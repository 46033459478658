@import 'src/styles/services';

.content {
  padding: 8px;
}

.button {
  &.button {
    height: 36px;
    width: 33px;
    border-radius: 0;
  }
}
